import React from 'react'
import { Container, Card } from '@material-ui/core'

/* It is created as a component function in the react hooks. */
class Expenses extends React.Component {
  render () {
    return (
      <Container fixed>
        <Card className='card'>
          <div>Hello Expenses</div>
        </Card>
      </Container>
    )
  }
}

export default Expenses
