import React from 'react'
import ReactDOM from 'react-dom'
import './scss/app.scss'
import ApplicationBar from './components/applicationBar'
import { MuiThemeProvider } from '@material-ui/core'
import BottomMenu from './components/bottomMenu'
import fincTheme from './theme'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Dashboard from './components/dashboard/Dashboard'
import Expenses from './components/expenses/Expenses'

class HelloMessage extends React.Component {
  constructor (props) {
    super(props)

    console.log('main', props)
  }

  render () {
    return (
      <div>
        <ApplicationBar />
        <Router>
          <Route path='/' exact component={Dashboard} />
          <Route path='/expenses' exact component={Expenses} />
          <BottomMenu />
        </Router>
      </div>
    )
  }
}

const App = document.getElementById('application')

ReactDOM.render(<MuiThemeProvider theme={fincTheme}><HelloMessage name='Jurrien & Irene' /></MuiThemeProvider>, App)
