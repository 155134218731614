import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ReceiptIcon from '@material-ui/icons/Receipt'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import SettingsIcon from '@material-ui/icons/Settings'

class BottomMenu extends Component {
  constructor (props) {
    super(props)

    this.history = props.history

    var pathValue = props.location.pathname.substring(1)
    if (pathValue === '') {
      pathValue = 'home'
    }
    this.state = {
      value: pathValue
    }

    this.handleChange = (event, value) => {
      this.setState({ value: value })
    }
  }

  render () {
    var historyPath = this.history.location.pathname.substring(1)
    if (historyPath === '') {
      historyPath = 'home'
    }
    if (this.state.value !== historyPath) {
      this.setState({ value: historyPath })
    }
    return (
      <BottomNavigation value={this.state.value} onChange={this.handleChange} className='bottomMenu'>
        <BottomNavigationAction component={Link} to='/' value='home' label='Home' icon={<DashboardIcon />} />
        <BottomNavigationAction component={Link} to='/expenses' value='expenses' label='Expenses' icon={<ReceiptIcon />} />
        <BottomNavigationAction component={Link} to='/savings' value='savings' label='Savings' icon={<MonetizationOnIcon />} />
        <BottomNavigationAction component={Link} to='/history' value='history' label='History' icon={<CalendarTodayIcon />} />
        <BottomNavigationAction component={Link} to='/settings' value='settings' label='Settings' icon={<SettingsIcon />} />
      </BottomNavigation>
    )
  }
}

export default withRouter(BottomMenu)
